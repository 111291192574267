<template>
  <div id="app">
    <Nav/>
    <router-view/>
    <Footer/>
    <!-- <button class="goTop" v-if="backToTopVisible" @click="backToTop">
      <img src="/images/icons/arrow-up.svg">
    </button> -->
  </div>
</template>
<script>
import Nav from "@/components/Nav";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Nav},
  data () {
    return {
      backToTopVisible: true
    }
  },
  watch: {
    '$i18n'() {
      console.log(this.$i18n)
    }
  },
  mounted () {
    console.log(this.$i18n.locale)
    if (this.$i18n.locale === 'zh'){
      document.getElementsByTagName('body')[0].classList.add('zh')
    }
  },
  methods : {
    backToTop: function() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
</script>
<style lang="scss">
@import "assets/scss/app.scss";
</style>
