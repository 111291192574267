import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import OurPractice from "@/views/OurPractice";
import OurExperience from "@/views/OurExperience";
import Professionals from "@/views/Professionals";
import ProfessionalsIndividual from "@/views/ProfessionalsIndividual";
import ContactUs from "@/views/ContactUs";
import About from "@/views/About";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Disclaimer from "@/views/Disclaimer";
import Page404 from "@/views/Page404";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about/',
        name: 'About',
        component: About,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/our-practice/',
        name: 'OurPractice',
        component: OurPractice,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/our-experience/',
        name: 'OurExperience',
        component: OurExperience,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/our-professionals/',
        name: 'OurProfessionals',
        component: Professionals,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/our-professionals/:name/',
        name: 'ProfessionalsIndividual',
        component: ProfessionalsIndividual,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/contact-us/',
        name: 'ContactUs',
        component: ContactUs,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/privacy-policy/',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '/disclaimer/',
        name: 'Disclaimer',
        component: Disclaimer,
        pathToRegexpOptions: { strict: true },
    },
    {
        path: '*',
        name: 'NotFound',
        component: Page404
    }
]

let _base = '/'
let locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

if (['en', 'zh'].includes(locale.trim())) {
    if (locale.trim() === 'en') {
        _base = '/'
    } else {
        _base = '/' + locale
    }
}


const router = new VueRouter({
    base: _base,
    // base: (locale.trim().length && locale !== "/") ? '/' + locale : undefined,
    mode: 'history',
    routes,
    scrollBehavior(to) {
        if (!to.hash) {
            return { x: 0, y: 0 }
        } else {
            return { selector: to.hash }
        }
    }
})

export default router
