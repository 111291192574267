<template>
  <div id="home" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/header2.png" alt="" class="landing-image">
      <img src="/images/header-mini.png" alt="" class="landing-image-mini">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="text-center">
           <img src="/images/wtc_logo_ori.png" alt="" class="header-logo">
        </div>
        <div class="heading">
          <h1 class="theme-title">{{ $t('home.heading.title') }}</h1>
          <p v-html="$t('home.heading.description')"></p>
          <p v-html="$t('home.heading.description2')"></p>
          <div class="ALB">
            <img class="" src="/images/Malaysia-Law-Awards-2022-Badge-Finalist-copy.png.png">
          </div>
        </div>
      </section>
      <div class="vertical-line-connector"></div>
      <section class="white-section">
        <div class="container">
          <h1 class="theme-title text-center mb-5">{{ $t('home.practice.title') }}</h1>
          <!-- <p class="text-center" v-html="$t('home.practice.meta')"></p> -->
          <b-row>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.corporate.title')"></div>
              </div>
            </b-col>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.probate_admin.title')"></div>
              </div>
            </b-col>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.real_estate.title')"></div>
              </div>
            </b-col>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.family_law.title')"></div>
              </div>
            </b-col>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.dispute_resolution.title')"></div>
              </div>
            </b-col>
            <b-col md="6" class="clip-area mb-4">
              <div class="chipped-outline-box ">
                <div class="text-center" v-html="$t('practice.intellectual_property.title')"></div>
              </div>
            </b-col>
          </b-row>

          <div class="w-100 text-center my-3">
            <router-link class="btn-chipped-black d-inline-block"
                        :to="{name: 'OurPractice'}">{{ $t('home.practice.learn_more') }}</router-link>
          </div>
        </div>
      </section>
      <div class="vertical-line-connector"></div>
      <section class="white-section">
        <div class="container">
          <h1 class="theme-title text-center mb-5">{{ $t('home.professionals.title') }}</h1>
          <div class="partnersImgDiv"><img class="partnersImg" src="/images/Team/WTC-partners.png"></div>
          <!-- <p class="text-center" v-html="$t('home.professionals.meta')"></p> -->

          <div class="row mt-5">
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'jeremy-wong-kwan-kit'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/WKK%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.jeremy-wong-kwan-kit.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'teoh-chen-yee'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/TCY%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.teoh-chen-yee.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'chang-lih-yik'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/CLY%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.chang-lih-yik.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
          </div>
          <div class="d-block d-flex flex-row justify-content-center mt-5">
            <router-link class="btn-chipped-black d-inline-block"
                        :to="{name: 'OurProfessionals'}">{{ $t('home.professionals.meet_our_team') }}</router-link>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo () {
    return { title: `${this.$t('nav.home')} - ${this.$t('site_name')}` }
  }
}
</script>
