<template>
  <footer>
    <div class="container main-container mt-0">
      <div class="white-section p-0">

        <div class="container">
          <b-row>
            <b-col>
              <div class="text-center text-uppercase my-4 contact-us-text">
                {{ $t("nav.contact_us") }}
              </div>
            </b-col>
          </b-row>

          <b-row class="px-2">
            <b-col md="4" lg="4" sm="4" class="overflow-hidden pb-4">
              <div class="footer-location">
                <h4 class="f-h4">{{ $t("footer.kuala_lumpur_office") }}</h4>
                <p class="address">
                  P-13-3, Bukit Jalil Signature Office, Jalan Persiaran Jalil 3,
                  Bukit Jalil, 57000 Kuala Lumpur, Malaysia
                </p>

                <p class="address">
                  {{ $t("footer.email") }} : kl@wtclegal.com <br />
                  {{ $t("footer.tel") }} : +603 27755065 / 27755066
                </p>
              </div>
            </b-col>
            <b-col md="4" lg="4" sm="4" class="overflow-hidden pb-4">
              <div class="footer-location">
                <h4 class="f-h4">{{ $t("footer.labuan_office") }}</h4>
                <p class="address">
                  F18 & F19, 1st Floor, Paragon Labuan, Jalan Tun Mustapha, 87013
                  Federal Territory of Labuan, Malaysia
                </p>
                <p class="address">
                  {{ $t("footer.email") }} : labuan@wtclegal.com <br />
                  {{ $t("footer.tel") }} : +6087 418121 / 418122
                </p>
              </div>
            </b-col>
            <b-col md="4" lg="4" sm="4" class="overflow-hidden pb-4">
              <div class="footer-location">
                <h4 class="f-h4">{{ $t("footer.ipoh_office") }}</h4>
                <p class="address">
                  25, Medan Stesen 19/5, Station 18,<br />
                  31650 Ipoh, Perak, Malaysia
                </p>
                <p class="address">
                  {{ $t("footer.email") }} : ipoh@wtclegal.com <br />
                  {{ $t("footer.tel") }} : +605 3210517
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="trademark-div px-2">
            <b-col md="6" sm="6" lg="6">
              <div class="d-flex align-items-center">
                <img src="/images/wtc_logo_white.png" width="50" height="50" />
                <div class="ms-2">
                  <div class="copyright">&copy; {{ year }} WTC</div>
                  <div>Advocates & Solicitors</div>
                </div>
              </div>
            </b-col>
            <b-col md="6" sm="6" lg="6" class="tnc-link-div">
              <div class="tnc-link-container">
                <router-link :to="{ name: 'PrivacyPolicy' }"
                  >Privacy Policy</router-link
                >
                <span class="px-2">|</span>
                <router-link :to="{ name: 'Disclaimer' }"
                  >Disclaimer</router-link
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      year: null,
    };
  },
  created() {
    this.year = new Date().getFullYear();
  },
};
</script>
