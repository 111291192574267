<template>
  <div id="professional" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/Team/WTC-all(edited2).png" alt="" class="landing-image landing-mahkamah">
      <img src="/images/Team/WTC-all.png" alt="" class="landing-image-mini landing-mahkamah">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading">
          <h1 class="theme-title mb-0">{{ $t('professionals.partner_profile') }}</h1>
        </div>
      </section>
      <section class="white-section pt-0">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'jeremy-wong-kwan-kit'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/WKK%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.jeremy-wong-kwan-kit.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'teoh-chen-yee'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/TCY%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.teoh-chen-yee.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'chang-lih-yik'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/CLY%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.chang-lih-yik.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
          </div>

          <div class="vertical-line-connector my-5"></div>

          <h1 class="theme-title text-center" id="associates">{{ $t('professionals.associate_profile') }}</h1>
          <div class="row mt-5">
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'olga-octavia-mathius'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/Olga%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.olga-octavia-mathius.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'teoh-kean-guan'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/TKG%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.teoh-kean-guan.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4">
              <router-link class="personLink" :to="{'name': 'ProfessionalsIndividual', params: {name: 'valence-tan-qhye-xyean'}}">
                <div class="hbImgChipped">
                  <img src="/images/Half%20Body/Valence%20half%20body.jpg" class=""/>
                </div>
                <span class="name">{{ $t('people.valence-tan-qhye-xyean.name') }}</span>
                <div class="text-center">
                  <svg class="arrow-circle-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 28.5946C22.6274 28.5946 28 23.222 28 16.5946C28 9.96719 22.6274 4.5946 16 4.5946C9.37258 4.5946 4 9.96719 4 16.5946C4 23.222 9.37258 28.5946 16 28.5946Z" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.7573 20.8372L20.9999 16.5946L16.7573 12.3519" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 16.5946H21" stroke="#163260" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
</template>
<script>
export default {
  name: 'OurProfessionals',
  metaInfo () {
    return { title: `${this.$t('nav.our_professionals')} - ${this.$t('site_name')}` }
  },
  data () {
    return {
    }
  }
}
</script>
