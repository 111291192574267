<template>
  <div id="professional-individual" v-if="lang_key" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/headers/mahkamah.png" alt="" class="landing-image landing-mahkamah">
      <img src="/images/headers/mahkamah.png" alt="" class="landing-image-mini landing-mahkamah">
    </section>
    <div class="container main-container">
      <section class="header white-section">
        <div class="container bg-white">
          <b-row>
            <b-col md="4">
              <div class="theme-border py-3 px-4">
                <div class="imgChipped">
                  <img :src="$t(`people.${lang_key}.headshot`)" class=""/>
                </div>

                <h1 class="theme-title mt-4 mb-2 mobile-only">{{ $t(`people.${lang_key}.name`) }}</h1>
                <h2 class="theme-title mobile-only">{{ $t(`people.${lang_key}.association`)}}</h2>

                <h2 class="theme-title mt-4 mb-2">
                    {{ $t('professionals_individual.contact')}}
                </h2>

                <div v-if="$t(`people.${lang_key}.tel_office`)">{{ $t('professionals_individual.office') }}: {{ $t(`people.${lang_key}.tel_office`) }}</div>
                <div v-if="$t(`people.${lang_key}.tel_mobile`)">{{ $t('professionals_individual.mobile') }}: {{ $t(`people.${lang_key}.tel_mobile`) }}</div>
                <div v-if="$t(`people.${lang_key}.email`)">{{ $t('professionals_individual.email') }}: {{ $t(`people.${lang_key}.email`) }}</div>

                <h2 class="theme-title mt-4 mb-2">
                    {{ $t('professionals_individual.bar_admission')}}
                </h2>
                <div v-html="$t(`people.${lang_key}.bar_admission`)"></div>

                <h2 class="theme-title mt-4 mb-2">
                    {{ $t('professionals_individual.education')}}
                </h2>
                <div v-html="$t(`people.${lang_key}.education`)"></div>

                <h2 class="theme-title mt-4 mb-2">
                    {{ $t('professionals_individual.practices')}}
                </h2>
                <div v-html="$t(`people.${lang_key}.practices`)"></div>

                <h2 class="theme-title mt-4 mb-2">
                    {{ $t('professionals_individual.languages')}}
                </h2>
                <div v-html="$t(`people.${lang_key}.languages`)"></div>

              </div>
            </b-col>
            <b-col>
              <h1 class="theme-title mb-2 desktop-only">{{ $t(`people.${lang_key}.name`) }}</h1>
              <h2 class="theme-title desktop-only">{{ $t(`people.${lang_key}.association`)}}</h2>

              <h2 class="theme-title mt-5">{{ $t('professionals_individual.background')}}</h2>
              <div v-html="$t(`people.${lang_key}.overview`)"></div>

              <h2 class="theme-title mt-5" v-if="$t(`people.${lang_key}.representation`)">{{ $t('professionals_individual.selected_representations')}}</h2>
              <div v-html="$t(`people.${lang_key}.representation`)"></div>
            </b-col>
          </b-row>

        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProfessionalsIndividual',
  components: {},
  props: {
    personKey: String
  },
  data () {
    return {
      lang_key: null
    }
  },
  created () {
    if (this.personKey) {
      this.lang_key = this.personKey
    }
    if (this.$route.params.name) {
      this.lang_key = this.$route.params.name
    }
  }
}
</script>
