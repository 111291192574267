<template>
  <div id="contact-us" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/about-us-landing.png" alt="" class="landing-image">
      <img src="/images/about-us-landing-mini.png" alt="" class="landing-image-mini">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading">
          <h1 class="theme-title">{{ $t('about.how_we_started') }}</h1>
          <p v-html="$t('about.history.description1')"></p>
          <p v-html="$t('about.history.description2')"></p>
          <p v-html="$t('about.history.description3')"></p>
        </div>
      </section>
      <div class="vertical-line-connector"></div>
      <section class="white-section">
        <h1 class="theme-title text-center">{{ $t('about.firm_overview.title') }}</h1>
        <img src="/images/firm-overview.png" class="clipped-img img-fluid w-100" alt="One-stop legal service provider" >
        <b-row class="mt-5 justify-content-around">
          <b-col md="5">
            <h2 class="theme-title">{{ $t('about.firm_overview.one_stop') }}</h2>
            <p>{{ $t('about.firm_overview.one_stop_description') }}</p>
          </b-col>
          <b-col md="5">
            <h2 class="theme-title">{{ $t('about.firm_overview.brand') }}</h2>
            <p>{{ $t('about.firm_overview.brand_description') }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-3 justify-content-around">
          <b-col md="5">
            <h2 class="theme-title">{{ $t('about.firm_overview.multilingual') }}</h2>
            <p>{{ $t('about.firm_overview.multilingual_description') }}</p>
          </b-col>
          <b-col md="5">
            <img src="/images/open-quotemark.png" class="d-block">
            <h3 class="theme-quote" v-html="$t('about.firm_overview.firm_quote')"></h3>
            <img src="/images/close-quotemark.png" class="d-block ms-auto">
          </b-col>
        </b-row>
        
      </section>
    </div>
  </div>
</template>
<script>

export default {
  name: 'About',
  metaInfo () {
    return { title: `${this.$t('nav.about_us')} - ${this.$t('site_name')}` }
  }
}
</script>
