<template>
  <div id="contact-us" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/Team/WTC-all.png" alt="" class="landing-image">
      <img src="/images/Team/WTC-all.png" alt="" class="landing-image-mini">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading">
          <h1 class="theme-title mb-0">{{ $t('contact_us.heading')}} </h1>
        </div>
      </section>
      <section class="white-section pt-0">
        <div class="container">
          <b-row>
            <b-col>
              <form action="#" id="contact-form" @submit.prevent="submitContactForm">
                <div class="row">
                  <div class="mt-3"></div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group mb-3">
                      <label class="theme-title">{{ $t('contact_us.name') }} *</label>
                      <div :class="{'valided': formValidate.name.required === true}">
                        <b-input
                          v-model="form.name"
                          placeholder="Your Name Here"
                          @blur="validateName"
                          :class="{
                            'has-error': formValidate.name.required === false,
                          }"
                          :disabled="isFormLoading"
                        ></b-input>
                      </div>
                      <div class="error-message theme-text" v-if="formValidate.name.required === false">
                        <ul class="error-ul">
                          <li v-if="formValidate.name.required === false">
                            Name is required
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group mb-3">
                      <label class="theme-title">{{ $t('contact_us.email') }} *</label>
                      <div :class="{'valided': formValidate.email.required === true && formValidate.email.emailFormat === true}">
                        <b-input
                          v-model="form.email"
                          placeholder="eg. me@example.com"
                          @blur="validateEmail"
                          :class="{
                            'has-error':
                              formValidate.email.required === false ||
                              formValidate.email.emailFormat === false,
                          }"
                          :disabled="isFormLoading"
                        ></b-input>
                      </div>
                      <div class="error-message theme-text" v-if="formValidate.email.required === false || formValidate.email.emailFormat === false">
                        <ul class="error-ul">
                          <li v-if="formValidate.email.required === false">
                            Email is required
                          </li>
                          <li v-if="formValidate.email.emailFormat === false">
                            Email has wrong format
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mb-3">
                      <label class="theme-title">{{ $t('contact_us.message') }}</label>
                      <b-textarea v-model="form.message" placeholder="Your Message Here" rows="5" :disabled="isFormLoading"></b-textarea>
                    </div>
                  </div>
                </div>
                <div class="mt-0 mt-sm-3 d-block d-flex flex-row justify-content-center">
                  <button type="submit" class="btn-chipped-black padded" :disabled="isFormLoading">
                    <span v-if="!isSubmitted">
                      <span v-if="isFormLoading">One Moment...</span>
                      <span v-else>Submit</span>
                    </span>
                    <div v-else class=" d-flex align-items-center justify-content-center">
                      <span>Information Received </span> 
                      <img src="/images/icons/CheckCircleWhite.png" class="success-icon-btn">
                    </div>
                  </button>
                </div>
              </form>
            </b-col>
          </b-row>
          <div class="vertical-line-connector my-5"></div>
          <h1 class="theme-title text-center mb-5">{{ $t('contact_us.our_location')}} </h1>

          <div class="row">
            <div class="col-12 col-sm-6 mb-2">
              <GmapMap
                  :center="{lat:3.053147, lng:101.670442}"
                  :zoom="16"
                  style="height: 100%; min-height: 250px;"
                  :options="mapOptions"
              >
                <GmapMarker
                    :position="{lat:3.053147, lng:101.670442}"
                    :clickable="true"
                    :draggable="true"
                />
              </GmapMap>
            </div>
            <div class="col-12 col-sm-6">
              <div class="clip-area overflow-visible">
                <div class="chipped-outline-box default-chip">
                  <div class="px-2">
                    <h2 class="theme-title text-uppercase mt-3 mt-sm-0">{{ $t('contact_us.kuala_lumpur_office') }}</h2>
                    <p class="theme-text">
                      P-13-3, Bukit Jalil Signature Office,
                      Jalan Persiaran Jalil 3, Bukit Jalil,
                      57000 Kuala Lumpur, Malaysia
                    </p>

                    <p class="theme-text">
                      {{ $t('contact_us.tel') }} : +603 27755065 / 27755066<br/>
                      {{ $t('contact_us.email') }} : kl@wtclegal.com<br/>
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 col-sm-6 mb-2">
              <GmapMap
                  :center="{lat:5.280735, lng:115.244920}"
                  :zoom="16"
                  style="height: 100%; min-height: 250px;"
                  :options="mapOptions"
              >
                <GmapMarker
                    :position="{lat:5.280735, lng:115.244920}"
                    :clickable="true"
                    :draggable="true"
                />
              </GmapMap>
            </div>
            <div class="col-12 col-sm-6">
              <div class="clip-area overflow-visible">
                <div class="chipped-outline-box default-chip">
                  <div class="px-2">
                    <h2 class="theme-title text-uppercase mt-3 mt-sm-0">{{ $t('contact_us.labuan_office') }}</h2>
                    <p class="theme-text">
                      F18 & F19, 1st Floor, Paragon Labuan,
                      Jalan Tun Mustapha, 87013 Federal
                      Territory of Labuan, Malaysia
                    </p>

                    <p class="theme-text">
                      {{ $t('contact_us.tel') }} : +6087 418121 / 418122<br/>
                      {{ $t('contact_us.email') }} : labuan@wtclegal.com<br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 col-sm-6 mb-2">
              <GmapMap
                  :center="{lat:4.545469, lng:101.066939}"
                  :zoom="16"
                  style="height: 100%; min-height: 250px;"
                  :options="mapOptions"
              >
                <GmapMarker
                    :position="{lat:4.545469, lng:101.066939}"
                    :clickable="true"
                    :draggable="true"
                />
              </GmapMap>
            </div>
            <div class="col-12 col-sm-6">
              <div class="clip-area overflow-visible">
                <div class="chipped-outline-box default-chip">
                  <div class="px-2">
                    <h2 class="theme-title text-uppercase mt-3 mt-sm-0">{{ $t('contact_us.ipoh_office') }}</h2>
                    <p class="theme-text">
                      25, Medan Stesen 19/5, Station 18,
                      31650 Ipoh, Perak, Malaysia
                    </p>

                    <p class="theme-text">
                      {{ $t('contact_us.tel') }} : +605 3210517<br/>
                      {{ $t('contact_us.email') }} : ipoh@wtclegal.com<br/>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </section>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'ContactUs',
  metaInfo () {
    return { title: `${this.$t('nav.contact_us')} - ${this.$t('site_name')}` }
  },
  data () {
    return {
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      isSubmitted: false,
      isFormLoading: false,
      form: {
        name: null,
        email: null,
        message: null
      },
      formValidate: {
        name: {
          required: null,
        },
        email: {
          required: null,
          emailFormat: null,
        }
      }
    }
  },
  methods: {
    validateName () {
      this.formValidate.name.required = this.form.name !== null && this.form.name.length > 0
    },
    validateEmail () {
      this.formValidate.email.required = this.form.email !== null && this.form.email.length > 0
      if (this.formValidate.email.required) {
        /* eslint-disable-next-line */
        this.formValidate.email.emailFormat = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(this.form.email)
      }
    },
    validateForm () {
      this.validateName()
      this.validateEmail()
      return this.formValidate.name.required && this.formValidate.email.required && this.formValidate.email.emailFormat
    },
    submitContactForm () {
      if (this.validateForm()) {
        this.isFormLoading = true
        window.axios.post(window.serverUrl + '/api/contact-us', {
            formData: this.form
          })
          .then((res) => {
            if (res.data.success) {
              this.isSubmitted = true
            } else {
              this.isFormLoading = false
            }
          })
          .catch(err => {
            console.log(err)
            this.isFormLoading = false
          })
      }
    }
  },
}
</script>
