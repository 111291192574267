import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './lang/en.json'
import zh from './lang/zh.json'
Vue.use(VueI18n);

const languages = {
    en: en,
    zh: zh,
};

let defaultLocale = 'en'
let locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

const i18n = new VueI18n({
    locale: (locale.trim().length && locale !== "/") ? locale : defaultLocale,
    fallbackLocale: 'en',
    messages: languages
});

export default i18n
