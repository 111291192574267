<template>
  <div id="practice" class="landing-root">
     <!-- url('/images/headers/04.jpg');" -->
    <section class="landing-image-section">
      <img src="/images/headers/practice-header.png" alt="" class="landing-image">
      <img src="/images/headers/practice-header-mini.png" alt="" class="landing-image-mini">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading">
          <h1 class="theme-title mb-0">{{ $t('practice.heading') }}</h1>
        </div>
      </section>
      <section class="white-section pt-0">
        <!-- DESKTOP ONLY -->
        <div class="container desktop-only">
          <b-row>
            <b-col md="4">
              <div class="position-sticky" style="top: 90px">
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'corporate'}" @click="activeTab = 'corporate'">
                    {{ $t('practice.corporate.title') }}
                  </div>
                </div>
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'dispute_resolution'}" @click="activeTab = 'dispute_resolution'">
                    {{ $t('practice.dispute_resolution.title') }}
                  </div>
                </div>
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'real_estate'}" @click="activeTab = 'real_estate'">
                    {{ $t('practice.real_estate.title') }}
                  </div>
                </div>
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'family_law'}" @click="activeTab = 'family_law'">
                    {{ $t('practice.family_law.title') }}
                  </div>
                </div>
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'probate_admin'}" @click="activeTab = 'probate_admin'">
                    {{ $t('practice.probate_admin.title') }}
                  </div>
                </div>
                <div class="clip-tab-area">
                  <div class="chipped-outline-box" :class="{'active-box': activeTab === 'intellectual_property'}" @click="activeTab = 'intellectual_property'">
                    {{ $t('practice.intellectual_property.title') }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="8">
              <div v-show="activeTab === 'corporate'">
                <p v-html="$t('practice.corporate.p1')"></p>
                <p v-html="$t('practice.corporate.p2')"></p>
                <p v-html="$t('practice.corporate.p3')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.corporate.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.corporate.pSub1')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub2')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub3')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub4')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub5')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub6')"></p></li>
                </ul>
              </div>

              <div v-show="activeTab === 'dispute_resolution'">
                <p v-html="$t('practice.dispute_resolution.p1')"></p>
                <p v-html="$t('practice.dispute_resolution.p2')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.dispute_resolution.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.dispute_resolution.pSub1')"></p></li>
                  <li><p v-html="$t('practice.dispute_resolution.pSub2')"></p></li>
                </ul>
              </div>

              <div v-show="activeTab === 'real_estate'">
                <p v-html="$t('practice.real_estate.p1')"></p>
                <p v-html="$t('practice.real_estate.p2')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.real_estate.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.real_estate.pSub1')"></p></li>
                  <li><p v-html="$t('practice.real_estate.pSub2')"></p></li>
                </ul>
              </div>

              <div v-show="activeTab === 'family_law'">
                <p v-html="$t('practice.others.sub1')"></p>
              </div>
              <div v-show="activeTab === 'probate_admin'">
                <p v-html="$t('practice.others.sub2')"></p>
              </div>
              <div v-show="activeTab === 'intellectual_property'">
                <p v-html="$t('practice.others.sub3')"></p>
              </div>
            </b-col>
          </b-row>

        </div>

        <!-- MOBILE ONLY -->
        <div class="container mobile-only">
          <div class="m-clip-tab-area">
            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'corporate'}" @click="activeTab !== 'corporate' ? activeTab = 'corporate' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.corporate.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'corporate'}">
                <p v-html="$t('practice.corporate.p1')"></p>
                <p v-html="$t('practice.corporate.p2')"></p>
                <p v-html="$t('practice.corporate.p3')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.corporate.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.corporate.pSub1')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub2')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub3')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub4')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub5')"></p></li>
                  <li><p v-html="$t('practice.corporate.pSub6')"></p></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="m-clip-tab-area">

            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'dispute_resolution'}" @click="activeTab !== 'dispute_resolution' ? activeTab = 'dispute_resolution' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.dispute_resolution.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'dispute_resolution'}">
                <p v-html="$t('practice.dispute_resolution.p1')"></p>
                <p v-html="$t('practice.dispute_resolution.p2')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.dispute_resolution.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.dispute_resolution.pSub1')"></p></li>
                  <li><p v-html="$t('practice.dispute_resolution.pSub2')"></p></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="m-clip-tab-area">

            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'real_estate'}" @click="activeTab !== 'real_estate' ? activeTab = 'real_estate' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.real_estate.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'real_estate'}">
                <p v-html="$t('practice.real_estate.p1')"></p>
                <p v-html="$t('practice.real_estate.p2')"></p>
                <h3 class="theme-title theme-title--none-tt" v-html="$t('practice.real_estate.pSubTitle')"></h3>
                <ul class="custom-bullet">
                  <li><p v-html="$t('practice.real_estate.pSub1')"></p></li>
                  <li><p v-html="$t('practice.real_estate.pSub2')"></p></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="m-clip-tab-area">

            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'family_law'}" @click="activeTab !== 'family_law' ? activeTab = 'family_law' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.family_law.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'family_law'}">
                <p v-html="$t('practice.others.sub1')"></p>
              </div>
            </div>
          </div>
          <div class="m-clip-tab-area">

            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'probate_admin'}" @click="activeTab !== 'probate_admin' ? activeTab = 'probate_admin' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.probate_admin.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'probate_admin'}">
                <p v-html="$t('practice.others.sub2')"></p>
              </div>
            </div>
          </div>
          <div class="m-clip-tab-area">

            <div class="chipped-outline-box" :class="{'active-box': activeTab === 'intellectual_property'}" @click="activeTab !== 'intellectual_property' ? activeTab = 'intellectual_property' : activeTab = null">
              <div class="chipped-content">
                <div class="chipped-title">
                  {{ $t('practice.intellectual_property.title') }}
                </div>
                <div>
                  <img src="/images/icons/caret-down.png" alt="" class="caret-control">
                </div>
              </div>
              <div class="ready-state" :class="{'rest-state': activeTab !== 'intellectual_property'}">
                <p v-html="$t('practice.others.sub3')"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OurPractices',
  metaInfo () {
    return { title: `${this.$t('nav.our_practice')} - ${this.$t('site_name')}` }
  },
  data () {
    return {
      activeTab: 'corporate'
    }
  },
  mounted(){
    console.log(document.cookie)
  },
  watch: {
    activeTab() {
      setTimeout(() => document.getElementsByClassName('header')[0].scrollIntoView(), 100)
    }
  }
}
</script>
