<template>
  <div class="langSwitch">
    <a href="#" 
       @click.prevent="switchLang('en')">ENG</a>
    <div class="lang-vertical-divider"></div>
    <a href="#" 
       @click.prevent="switchLang('zh')">中文</a>
  </div>
</template>
<script>
export default {
  name: 'LangSwitch',
  created () {
  },
  methods: {
    switchLang(lang) {
      let _href = location.protocol + '//' + location.host + '/' + lang + this.$route.fullPath
      if (lang === 'en') {
        _href = location.protocol + '//' + location.host + this.$route.fullPath
      }
      this.setCookie('currentLang', lang, 7)
      window.location.href = _href
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    eraseCookie(name) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
}
</script>
