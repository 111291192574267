import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueMeta)

window.axios = require('axios')
window.serverUrl = process.env.VUE_APP_SERVER_URL

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCwxzuFAdQIUi2HUUPew-RGqjBSriyS5Bc',
    libraries: 'places', // This is required if you use the Autocomplete plugin
}})


// redirect before render
if (window.location.pathname.slice(-1) !== '/') {
  window.location.href = window.location.pathname + '/'
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  i18n,
}).$mount('#app')
