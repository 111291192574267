<template>
  <div id="privacy-policy" class="landing-root">
    <section class="landing-image-section pb-5">
    </section>
    <div class="container main-container" id="mainContainer">
      <section class="header">
        <div class="heading pb-0">
          <h1 class="theme-title mb-0">Privacy Policy</h1>
        </div>
      </section>
      <section class="white-section pt-0 m-t-48">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <b-tabs>
                <b-tab title="English">
                  <div class="col-12 my-3">
                    <vue-markdown :source="engSource"></vue-markdown>
                  </div>
                </b-tab>
                <b-tab title="Bahasa Malaysia">
                  <div class="col-12 my-3">
                    <vue-markdown :source="bmSource"></vue-markdown>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import privacyEng from '../markdowns/privacy-eng.md'
import privacyBm from '../markdowns/privacy-bm.md'

export default {
  name: 'PrivacyPolicy',
  components: {
    VueMarkdown
  },
  data () {
    return {
      engSource: '',
      bmSource: '',
    }
  },
  mounted () {
    this.engSource = privacyEng
    this.bmSource = privacyBm
  }
}
</script>

<style>
section{
  letter-spacing: 0 !important;
}
</style>