<template>
  <div id="contact-us" class="landing-root">
    <section class="landing-image-section">
      <img src="/images/headers/mahkamah.png" alt="" class="landing-image landing-mahkamah">
      <img src="/images/headers/mahkamah.png" alt="" class="landing-image-mini landing-mahkamah">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading">
          <h1 class="theme-title mb-0">{{  $t('experience.heading') }}</h1>
        </div>
      </section>
      <section class="white-section pt-0">
        <div class="container">
          <b-row>
            <b-col md="6">
              <div class="clip-area overflow-visible">
                <div class="chipped-outline-box">
                  <div class="text-center text-uppercase">{{ $t('experience.transaction_matters.title') }}</div>
                </div>
              </div>
              <img src="/images/transaction-matter.png" class="mt-4 w-100 mobile-only">
              <p class="mt-4">{{ $t('experience.transaction_matters.description1') }}</p>
              <p>{{ $t('experience.transaction_matters.description2') }}</p>
              <p>{{ $t('experience.transaction_matters.description3') }}</p>
            </b-col>
            <b-col md="6" class="desktop-only mb-5">
              <img src="/images/transaction-matter.png" class="w-100">
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="desktop-only">
              <img src="/images/non-transaction-matter.png" class="w-100">
            </b-col>
            <b-col md="6">
              <div class="clip-area overflow-visible">
                <div class="chipped-outline-box">
                  <div class="text-center text-uppercase">{{ $t('experience.non_transaction_matters.title') }}</div>
                </div>
              </div>
              <img src="/images/non-transaction-matter.png" class="mt-4 w-100 mobile-only">
              <p class="mt-4">{{ $t('experience.non_transaction_matters.description1') }}</p>
              <p>{{ $t('experience.non_transaction_matters.description2') }}</p>
            </b-col>
          </b-row>
        </div>
      </section>
    </div>
    
  </div>
</template>
<script>

export default {
  name: 'OurExperience',

  metaInfo () {
    return { title: `${this.$t('nav.our_experience')} - ${this.$t('site_name')}` }
  }
}
</script>
