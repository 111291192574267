<template>
    <b-navbar id="mainNav" class="" toggleable="lg">
      <div class="container">
        <b-navbar-brand :to="{path: '/'}">
          <img src="/images/wtc_logo_small_ori.png" class="img-responsive"/>
        </b-navbar-brand>

        <b-navbar-nav class="mobile-only w-auto">
            <b-nav-item class="contact-us-link" active-class="active" :to="{name: 'ContactUs'}">{{ $t('nav.contact_us') }}</b-nav-item>

        </b-navbar-nav>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>


        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item class="nav-custom" active-class="active" :to="{name: 'Home'}" exact>{{ $t('nav.home') }}</b-nav-item>

            <b-nav-item class="nav-custom" active-class="active" :to="{name: 'About'}">{{ $t('nav.about_us') }}</b-nav-item>

            <!-- <div @mouseover="onDropOver('drop_about_us')" @mouseleave="onDropLeave('drop_about_us')" @click="onDropClick('drop_about_us')">
              <b-nav-item-dropdown active-class="active" ref="drop_about_us">
                <template #button-content>
                  {{  $t('nav.about_us') }}
                </template>
                <b-dropdown-item :to="{path: '/about#firm_overview'}">{{ $t('nav.firm_overview') }}</b-dropdown-item>
                <b-dropdown-item :to="{path: '/about#culture'}">{{ $t('nav.our_value_and_culture') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div> -->
          
            <b-nav-item class="nav-custom" active-class="active" :to="{name: 'OurPractice'}">{{ $t('nav.our_practice') }}</b-nav-item>

            <!-- <div @mouseover="onDropOver('drop_practice')" @mouseleave="onDropLeave('drop_practice')" @click="onDropClick('drop_practice')">
              <b-nav-item-dropdown active-class="active" ref="drop_practice">
                <template #button-content>
                  {{  $t('nav.our_practice') }}
                </template>
                <b-dropdown-item :to="{path: '/our-practice#corporate'}">{{ $t('nav.corporate_commercial') }}</b-dropdown-item>
                <b-dropdown-item :to="{path: '/our-practice#dispute'}">{{ $t('nav.dispute_resolution') }}</b-dropdown-item>
                <b-dropdown-item :to="{path: '/our-practice#realestate'}">{{ $t('nav.real_estate') }}</b-dropdown-item>
                <b-dropdown-item :to="{path: '/our-practice#others'}">{{ $t('nav.others') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div> -->
            
            <b-nav-item class="nav-custom" active-class="active" :to="{name: 'OurExperience'}">{{ $t('nav.our_experience') }}</b-nav-item>
        
            <b-nav-item class="nav-custom" active-class="active" :to="{name: 'OurProfessionals'}">{{ $t('nav.our_people') }}</b-nav-item>

            <!-- <div @mouseover="onDropOver('drop_people')" @mouseleave="onDropLeave('drop_people')" @click="onDropClick('drop_people')">
              <b-nav-item-dropdown active-class="active" ref="drop_people">
                <template #button-content>
                  {{  $t('nav.our_people') }}
                </template>
                <b-dropdown-item :to="{path: '/our-professionals#partners'}">{{ $t('nav.partners') }}</b-dropdown-item>
                <b-dropdown-item :to="{path: '/our-professionals#associates'}">{{ $t('nav.associates') }}</b-dropdown-item>
              </b-nav-item-dropdown>
            </div> -->

            <b-nav-item class="contact-us-link desktop-only pe-2" active-class="active" :to="{name: 'ContactUs'}">{{ $t('nav.contact_us') }}</b-nav-item>
            <b-nav-text class="nav-custom p-m-0 p-0">
              <LangSwitch/>
            </b-nav-text>
          </b-navbar-nav>
        </b-collapse>
      </div>


     
    </b-navbar>
</template>
<script>
import LangSwitch from "@/components/LangSwitch";

export default  {
  name: 'Nav',
  components: {LangSwitch},
  methods: {
    onDropOver(_target) {
      this.$refs[_target].visible = true;
    },
    onDropLeave(_target) {
      this.$refs[_target].visible = false;
    },
    onDropClick(_target) {
      this.$refs[_target].visible = true;
    },
  }

}
</script>
