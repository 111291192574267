<template>
  <div id="privacy-policy" class="landing-root">
    <section class="landing-image-section pb-5">
    </section>
    <div class="container main-container">
      <section class="header">
        <div class="heading pb-0">
          <h1 class="theme-title mb-0">Disclaimer</h1>
        </div>
      </section>
      <section class="white-section pt-0 m-t-48">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <vue-markdown :source="source"></vue-markdown>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import disclaimer from '../markdowns/disclaimer.md'

export default {
  name: 'Disclaimer',
  components: {
    VueMarkdown
  },
  data () {
    return {
      source: '',
    }
  },
  mounted () {
    this.source = disclaimer
  }
}
</script>
